import React from 'react';

const Github: React.FC = () => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.30433 15.9771V13.7458H4.02148V13.2159H3.44576V12.6545H2.8729V12.0816H2.29717V11.5202H3.41711V12.0673H3.97565V12.6401H4.55138V13.2159H5.69997V12.6688H6.30433V12.0816H6.86287V11.4629H4.63731V10.9015H4.00716V10.343H3.44576V9.76152H2.90154V6.36445H3.46008V5.77727H3.97565V5.18722H3.46008V3.45146H5.14143V4.06728H5.74293V4.62868H6.33298V4.0816H9.72145V4.62868H10.323V4.05296H10.9416V3.45146H12.5915V5.18722H12.0329V5.74863H12.5915V6.33581H13.1815V9.76152H12.6058V10.343H12.0186V10.9187H11.4429V11.4629H10.7096H9.75009H9.17437V12.0673H9.75009V12.6401H10.323V15.9771H10.8987V15.4987H12.0902V14.9803H12.6201V14.3931H13.2102V13.803H13.7257V13.2273H14.3444V12.6401H14.9316V11.4629H15.4787V10.3716H16.0372V6.33581H15.493V5.20155H14.9173V4.00999H14.3158V2.91869H13.783V2.33151H13.1815V1.75579H12.6344V1.18293H12.0186V0.59288H10.9273V0.0200195H5.72861V0.59288H3.97565V1.16861H3.44576V1.74147H2.85571V2.25991H2.29717V2.90437H1.72145V4.02432H1.14859V5.1729H0.572861V6.30717H0V10.3573H0.572861V11.4772H1.10562V12.6831H1.73577V13.2159H2.28285V13.8174H2.85571V14.3645H3.44576V14.923H3.98998V15.4987H5.10992V15.9771H6.30433Z"
      fill="currentColor"
    />
  </svg>
);

export default Github;
