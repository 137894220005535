import React from 'react';
import IconCloseHappy from '@/components/icons/IconCloseHappy';
import { classNames } from '@/lib/utils';
type BoxTitleProps = {
  title: string;
  type?: 'close' | 'happy';
  onClose?: () => void;
};
export default function BoxTitle({ title, onClose, type = 'close' }: BoxTitleProps) {
  return (
    <div className="border-b-2 border-porchetta-green flex p-1">
      <h3 className="items-center border-2  border-porchetta-green flex flex-1 text-sm justify-between p-1">
        {title}{' '}
        <button
          className={classNames({
            'w-6': true,
            'border-2 border-porchetta-green': type === 'close',
            pointer: !!onClose,
            'bg-transparent items-center text-porchetta-green flex': true,
            'select-none outline-none focus:outline-none leading-5 justify-center text-xl': true,
          })}
          type="button"
          onClick={onClose}>
          {type === 'close' ? '×' : <IconCloseHappy />}
        </button>
      </h3>
    </div>
  );
}
