import React from 'react';

import Wrapper from './Wrapper';

type MainContentProps = {
  children?: React.ReactNode;
};

const MainContent: React.FC<MainContentProps> = ({ children }: MainContentProps) => (
  <main className="bg-porchetta-dark text-porchetta-green min-h-screen flex  justify-center mx-4 flex-grow overflow-x-hidden">
    <Wrapper>{children}</Wrapper>
  </main>
);

export default MainContent;
