import api from './http/api';
import { getCookieValue } from './utils';

export const OAUTH_TOKEN = 'logged_in';
export const REFRESH_TOKEN_URL = '/auth/refresh';
export const LOGIN_URL = '/auth/login';
export const ACCOUNT_INFO_URL = '/account/me';
export const LOGOUT_URL = '/auth/logout';

export const isOAuthTokenAvailable = (): boolean => getCookieValue('logged_in') === 'true';

export const tryOAuthLoginWithRefreshToken = async () => {
  if (isOAuthTokenAvailable()) {
    const result = await api.post<{ access_token: string }>(REFRESH_TOKEN_URL);
  }
};

export const oAuthLogout = async (): Promise<{ success: boolean }> => {
  return await api.post<{ success: boolean }>(LOGOUT_URL);
};
