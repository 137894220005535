import * as React from 'react';
import GenericAlert from './GenericAlert';
import useAlerts from '@/components/hooks/useAlert';
import IAlert from '@/lib/res/IAlert';

export default function GlobalAlertContainer(): JSX.Element {
  const { alerts, removeAlert } = useAlerts();
  const dismiss = (alert: IAlert) => () => removeAlert(alert);

  return (
    <div className="max-w-md mx-auto">
      {alerts.map((alert) => {
        return (
          <GenericAlert alert={alert} key={alert.id} dismiss={dismiss(alert)}>
            {alert.content}
          </GenericAlert>
        );
      })}
    </div>
  );
}
