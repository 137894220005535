import React from 'react';
import BoxTitle from '@/components/box/BoxTitle';

type BoxProps = {
  title: string;
  children: React.ReactNode;
  type?: 'close' | 'happy';
  onClose?: () => void;
};
const Box: React.FC<BoxProps> = ({ title, children, onClose, type = 'close' }: BoxProps) => (
  <div className="bg-porchetta-dark border-2 text-porchetta-green border-porchetta-green shadow-porchetta">
    <BoxTitle type={type} title={title} onClose={onClose} />
    <div className="py-2 px-1 bg-porchetta-dark">{children}</div>
  </div>
);

export default Box;
