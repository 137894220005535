import api from '@/lib/http/api';
import { getCookieValue } from './utils';

type StoreToken = {
  value: string | null;
  timer: null | number;
};

type StorePair = {
  loading: boolean;
  access_token: StoreToken;
  refresh_token: StoreToken;
};

const __TOKENS_STORE__: StorePair = {
  loading: false,
  access_token: {
    value: null,
    timer: null,
  },
  refresh_token: {
    value: null,
    timer: null,
  },
};

export async function logout() {
  __TOKENS_STORE__.access_token.value = null;
  __TOKENS_STORE__.refresh_token.value = null;
  try {
    localStorage.setItem('refresh_token', '');
  } catch (e) {
    console.error(`Imposible to delete token at logout`);
  }
}

export async function login(values: {
  username: string;
  password: string;
  recaptchaValue: string;
}) {
  const res = await api.post<{ access_token: string; refresh_token: string }>(
    `/auth/token`,
    { email: values.username, password: values.password, recaptchaValue: values.recaptchaValue },
    'json',
  );
  if (!res.access_token) {
    throw new Error(`Response without access token`);
  }
  saveLoginTokens(res);
}

export const isAuth = async (): Promise<boolean> => {
  try {
    const result = await api.get(`/account/me`);
    if (result.user.email) return true;
    return false;
  } catch (e) {
    return false;
  }
};
export async function register({
  username: email,
  recaptchaValue,
}: {
  username: string;
  recaptchaValue: string;
}) {
  const result = await api.post<{ id: string }>(`/users/`, { email, recaptchaValue }, 'json');
}

export async function confirmAccount({
  password,
  token: confirmation_token,
}: {
  password: string;
  token: string;
}) {
  const result = await api.post<{ email: string }>(
    `/users/confirmations`,
    { password, confirmation_token },
    'json',
  );
  return result;
}

type EncodedToken = {
  // This will probably be the registered claim most often used. This will define the expiration in NumericDate value. The expiration MUST be after the current date/time.
  exp: number;
  fresh: boolean;
  // The time the JWT was issued. Can be used to determine the age of the JWT
  iat: number;
  // Unique identifier for the JWT. Can be used to prevent the JWT from being replayed. This is helpful for a one time use token.
  jti: string;
  // Defines the time before which the JWT MUST NOT be accepted for processing
  nbf: number;
  sub: string; // email
  type: string;
};

const parseJwt = (token: string): EncodedToken | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function saveToken(name: 'access_token', value: string) {
  __TOKENS_STORE__[name].value = value;
  if (__TOKENS_STORE__[name].timer) {
    //@ts-ignore
    clearTimeout(__TOKENS_STORE__[name].timer);
  }
  const parsed = parseJwt(value);
  if (!parsed) {
    throw new Error(`Error parsing jwt token`);
  }
  const expirationSeconds = Math.max(parsed.exp - new Date().getTime() / 1000, 0);
  const expMl = Math.min(expirationSeconds * 1000, 60 * 60 * 24 * 1000);
  console.warn(`Setting expire for ${name} in ${expirationSeconds} seconds in ${expMl}ml.`);
  //@ts-ignore
  __TOKENS_STORE__[name].timer = setTimeout(async () => {
    console.warn(`Expiring token: ${name} after ${expMl}`);
    __TOKENS_STORE__[name].timer = 0;
    __TOKENS_STORE__[name].value = null;
    // silent login
    await tryLoginWithRefreshToken();
  }, expMl - 1000 * 60);
}

function saveRefreshToken(name: 'refresh_token', value: string, storage = false) {
  __TOKENS_STORE__[name].value = value;
  if (storage) {
    try {
      localStorage.setItem(name, value);
    } catch (e) {
      console.error(`Imposible to persist token in storage`, e);
    }
  }
}

export function getRefreshToken() {
  return __TOKENS_STORE__.refresh_token.value;
}

export async function tryLoginWithRefreshToken() {
  let refresh = __TOKENS_STORE__.refresh_token.value;
  if (refresh === null) {
    try {
      refresh = localStorage.getItem('refresh_token');
    } catch (e) {
      console.error(`Not access to localStorage`);
    }
  }
  // if (refresh === null || refresh === '') {
  //   return false;
  // }
  __TOKENS_STORE__.refresh_token.value = refresh;
  __TOKENS_STORE__.loading = true;
  try {
    const result = await api.post<{ access_token: string }>(`/auth/refresh`);
    saveToken('access_token', result.access_token);
  } catch (e) {
    console.error(e);
    __TOKENS_STORE__.access_token.value = null;
  }
  __TOKENS_STORE__.loading = false;
}

export function saveLoginTokens({
  access_token,
  refresh_token,
}: {
  access_token: string;
  refresh_token: string;
}) {
  saveToken('access_token', access_token);
  saveRefreshToken('refresh_token', refresh_token, true);
}

export function getToken(): string | null {
  return __TOKENS_STORE__.access_token.value;
}

export function isLogged(): boolean {
  return getCookieValue('logged_in') === 'true';
}

export function isLoading() {
  return __TOKENS_STORE__.loading;
}

export function requireUserFetcher<T>(path: string) {
  const apiFetcher = api.getFetcher<T>(path);
  return async () => {
    return ((!isLogged()
      ? Promise.reject('Unauthorized.')
      : apiFetcher()) as unknown) as Promise<T>;
  };
}
