import useSWR from 'swr';
import { OAuthUser } from '@/lib/res/IUser';
import { requireUserFetcher } from '@/lib/auth';
import ROUTES from '@/lib/routes';
import { useRouter } from 'next/router';
import { oAuthLogout } from '@/lib/oAuth';

export default function useOAuthUser() {
  const router = useRouter();
  const { data, mutate, error } = useSWR(
    'oauth_user',
    requireUserFetcher<OAuthUser | null>('/account/me'),
    { shouldRetryOnError: false },
  );

  async function doLogout() {
    oAuthLogout().then((result) => result.success && router.reload());
  }

  const loading = !data && !error;
  const loggedOut = !!error || data === null || typeof data === 'undefined';
  const oAuthUser = !loggedOut && data && data.email ? data : null;

  return {
    doLogout,
    loading: loading,
    oAuthLoggedOut: loggedOut || !oAuthUser,
    oAuthUser,
    mutateOAuth: mutate,
  };
}
