import React from 'react';

import Wrapper from './Wrapper';
import Navbar from '@/components/Menu/Navbar';

type HeaderProps = {
  children?: React.ReactNode;
};

const Header: React.FC<HeaderProps> = ({ children }: HeaderProps) => (
  <header className="bg-porchetta-dark text-porchetta-green flex justify-center p-4">
    <div className="flex-auto relative ">
      <Navbar />
    </div>
  </header>
);

export default Header;
