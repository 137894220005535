import * as React from 'react';
import { PropsWithChildren, useEffect, useRef } from 'react';
import start, { cancel } from '@/lib/porkSetup';

export default function HeroAbsolutePork({ children }: PropsWithChildren<{ title?: string }>) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    start(canvas);
    return () => {
      cancel();
    };
  }, []);
  return (
    <div className="z-10 absolute inset-x-0 top-0 min-h-[45rem] sm:min-h-[45rem] md:min-h-[45rem] select-none">
      <div className="aspect-w-7 lg:aspect-w-16 absolute inset-x-0 top-72 lg:top-0 aspect-h-7 lg:aspect-h-9">
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
}
