import * as React from 'react';
import { PropsWithChildren } from 'react';
import IconHappyPork from '@/components/icons/IconHappyPork';
import BoxTitle from '@/components/box/BoxTitle';
import IAlert from '@/lib/res/IAlert';
import Transition from '@/components/transition/Transition';
import IconSadPork from '@/components/icons/IconSadPork';
import Button from '@/components/inputs/Button';
import IconAnonymous from '@/components/icons/IconAnonymous';
import { classNames } from '@/lib/utils';
import IconConfirmPork from '@/components/icons/IconConfirmPork';

type AlertProps = {
  dismiss: () => void;
  alert: IAlert;
};

function title({ title, type }: IAlert) {
  if (title) {
    return title;
  }
  if (type === 'success') {
    return `Success!!`;
  }
  if (type === `error`) {
    return `Error!`;
  }
  return `Message!`;
}

export default function GenericAlert({ children, dismiss, alert }: PropsWithChildren<AlertProps>) {
  return (
    <Transition
      show={true}
      appear={true}
      enter="transition ease-out duration-300 transform"
      enterFrom="opacity-0  translate-x-1/2 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="translate-y-0 sm:scale-100"
      leave="transition ease-in duration-200 transform"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 translate-x-1/2 sm:translate-y-0 sm:scale-95">
      <div className="absolute max-w-md mx-auto bg-porchetta-dark border-2 text-porchetta-green border-porchetta-green z-40">
        <BoxTitle title={title(alert)} onClose={dismiss} />
        <div className="flex min-h-[10rem] items-center max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="">
            <div
              className={classNames({
                'aspect-h-10 aspect-w-10 w-32 text-green-400': true,
              })}>
              {alert.type === 'success' ? <IconHappyPork /> : null}
              {alert.type === 'error' ? <IconSadPork /> : null}
              {alert.type === 'confirm' ? <IconConfirmPork /> : null}
            </div>
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-porchetta-green">{children}</p>
          </div>
        </div>
        {alert.type === 'confirm' && (
          <div className="mx-auto p-4 sm:px-4 lg:px-8 flex justify-end items-center space-x-4">
            <a href="#cancel" onClick={dismiss}>
              Cancel
            </a>
            <Button
              onClick={(ev) => {
                ev.preventDefault();
                alert.onConfirm && alert.onConfirm();
                dismiss();
              }}
              outline
              dark
              size={'large'}>
              Confirm
            </Button>
          </div>
        )}
      </div>
    </Transition>
  );
}
