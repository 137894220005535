import * as React from 'react';
import LoginForm from '@/components/forms/LoginForm';
import Modal from '@/components/Modal/Modal';
import useModal from '@/components/hooks/useModal';

const ModalLogin = () => {
  const { closeLogin, modalLogin } = useModal();
  return (
    <Modal title="Welcome back" show={modalLogin} onClose={closeLogin}>
      <div className="flex justify-center p-4">
        <div className="flex flex-col">
          <h2 className="text-5xl uppercase font-titles-bold font-extrabold ">Log in</h2>
          <div className="mt-7 mb-4">
            <LoginForm />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalLogin;
