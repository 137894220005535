const theme = {
  colors: {
    green: '#00ff00',
    white: '#fff',
    dark: '#000',
    red: '#ff0000',
  },
  global: {
    fontSize: '16px',
    fontFamily: 'SourceCodePro-Regular',
  },
  layout: {
    wrapper: {
      maxWidth: '1024px',
      horizontalPadding: '1rem',
    },
    header: {
      verticalPadding: '1rem',
    },
    content: {
      verticalPadding: '1rem',
    },
    footer: {
      verticalPadding: '1rem',
    },
  },
};

type ThemePorchetta = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemePorchetta {}
}

export type { ThemePorchetta };
export default theme;
