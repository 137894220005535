import * as React from 'react';
import { PropsWithChildren } from 'react';
import Head from 'next/head';
import { GoogleFonts } from 'next-google-fonts';
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import MainContent from '@/components/layout/MainContent';
import Styles from '@/components/App/Styles';
import GlobalAlertContainer from '@/components/alerts/GlobalAlertContainer';
import Wrapper from '@/components/layout/Wrapper';
import ModalLogin from '@/components/auth/ModalLogin';
import ModalRegister from '@/components/auth/ModalRegister';
import HeroAbsolutePork from '@/components/widgets/HeroAbsolutePork';

const SITE_TITLE = `Porchetta Industries`;

export function makeTitle(title: string = ''): string {
  if (!title) {
    return `${SITE_TITLE}`;
  }

  return `${title} - ${SITE_TITLE}`;
}

export default function Main({
  children,
  isHome = false,
}: PropsWithChildren<{ isHome?: boolean }>) {
  return (
    <div className="h-full">
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href="https://porchetta.industries/favicon.ico" />
        <meta name="twitter:card" content="summary_large_image" />
        <title>Porchetta Industries</title>
      </Head>
      {isHome ? <HeroAbsolutePork /> : null}
      <div className="flex flex-col min-h-screen bg-porchetta-dark px-4">
        <Styles>
          <Header />
          <div className="bg-porchetta-dark text-porchetta-green flex justify-center">
            <Wrapper>
              <GlobalAlertContainer />
            </Wrapper>
          </div>
          <MainContent>
            {children}
            <ModalLogin />
            <ModalRegister />
          </MainContent>
          <Footer />
        </Styles>
      </div>
    </div>
  );
}
