import Link from 'next/link';
import React from 'react';
import Wrapper from './Wrapper';
import ROUTES from '@/lib/routes';
import Box from '@/components/box/Box';
import Twitter from '@/components/icons/Twitter';
import Discord from '@/components/icons/Discord';
import LinkedIn from '@/components/icons/LinkedIn';
import Github from '@/components/icons/Github';
import { useRouter } from 'next/router';
import IconUp from '@/components/icons/IconUp';

type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  const router = useRouter();
  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => router.push({ pathname: ROUTES.home }, undefined, { shallow: true }), 1000);
  };
  return (
    <footer id="footer" className="relative z-0 text-porchetta-black  mt-10 sm:mt-0 mb-20">
      <div id="footer-green" className="bg-porchetta-green my-5 flex justify-center">
        <Wrapper>
          <div className="flex my-10 flex-col sm:flex-row sm:max-w-lg mx-auto sm:justify-between px-10 sm:px-0">
            <div>
              <h4 className="text-base font-bold mb-2">Get in Touch</h4>
              <ul className="text-sm space-y-2">
                <li>
                  <a href="https://blog.porchetta.industries/">Blog</a>
                </li>
                <li>
                  <Link href={ROUTES.contact}>
                    <a>Contact us</a>
                  </Link>
                </li>
                <li>
                  <Link href={ROUTES.faq}>
                    <a>F.A.Q.</a>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-10 sm:mt-0">
              <h4 className="text-base font-bold mb-2">About us</h4>
              <ul className="text-sm  space-y-2">
                <li>
                  <Link href={ROUTES.about}>
                    <a>About Porchetta</a>
                  </Link>
                </li>
                <li>
                  <Link href={ROUTES.team}>
                    <a>Team</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Wrapper>
      </div>
      <p className="text-center my-5 font-sm text-porchetta-green">
        © Porchetta Industries 2021 |{' '}
        <Link href={ROUTES.terms}>
          <a>Terms & Conditions</a>
        </Link>{' '}
        |{' '}
        <Link href={ROUTES.privacy}>
          <a>Privacy</a>
        </Link>
      </p>
      <div className="mt-8 sm:mt-0 w-full flex justify-center text-porchetta-green">
        <div className="flex m-auto space-x-4">
          <a className="inline-flex w-7" href="https://discord.gg/tfyxKw5XW9">
            <Discord />
          </a>
          <a className="inline-flex w-7" href="https://twitter.com/porchetta_ind">
            <Twitter />
          </a>
          <a className="inline-flex w-7" href="https://github.com/Porchetta-Industries/">
            <Github />
          </a>
          <a
            className="inline-flex w-7"
            href="https://www.linkedin.com/company/porchetta-industries/">
            <LinkedIn />
          </a>
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  children: undefined,
};

export default Footer;
