import { NextRouter } from 'next/router';

const ROUTES = {
  home: '/',
  login: '/login',
  register: '/register',
  subscriptions: '/subscriptions',
  subscription: '/subscription',
  profile: '/profile',
  contact: '/contact',
  faq: '/faq',
  about: '/about',
  team: '/team',
  terms: '/terms',
  privacy: '/privacy',
  changePassword: `${process.env.NEXT_PUBLIC_GITEA_URL}/user/settings/account`,
  forgotPassword: '/forgot-password',
  usersAdmin: '/users-admin',
  subscriptionInfo: '/subscription',
};

export function isActive(router: NextRouter, route: string) {
  return router.pathname === route;
}

export function isValidRedirect(link: any) {
  if (typeof link !== 'string') {
    return false;
  }
  return Object.values(ROUTES).indexOf(link) > -1 || link.indexOf('/') === 0;
}

export default ROUTES;
