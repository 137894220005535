import React from 'react';
import { ThemeProvider, Global, css } from '@emotion/react';
import theme from "@/lib/legacy-theme";

type GlobalStylesProps = {
  children: React.ReactNode;
};

const globalStyles = css`
  html,
  body,
  #root {
    margin: 0;
    min-height: calc(100vh);
    padding: 0;
  }
`;

const GlobalStyles: React.FC<GlobalStylesProps> = ({ children }: GlobalStylesProps) => (
  <ThemeProvider theme={theme}>
    <Global styles={globalStyles} />
    {children}
  </ThemeProvider>
);

export default GlobalStyles;
