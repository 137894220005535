import { getRefreshToken, getToken, isLogged } from '@/lib/auth';

export class ApiError extends Error {
  public status: number = 0;
  public resJson: object = {};

  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

type Format = 'urlencoded' | 'json';

export class Api {
  protected readonly browser = typeof window !== 'undefined';
  protected readonly base: string = process.env.NEXT_PUBLIC_API_BASE as string;
  protected readonly _headers: { [k: string]: string } = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Accept: 'application/json',
  };

  getContentType(type: Format) {
    return type === 'urlencoded'
      ? 'application/x-www-form-urlencoded; charset=UTF-8'
      : 'application/json';
  }

  getBody(type: Format, body = {}) {
    return type === 'urlencoded' ? new URLSearchParams(body) : JSON.stringify(body);
  }

  async post<T>(path: string, body = ({} = {}), format: Format = 'json') {
    return await this._reqWithBody<T>('POST', path, body, format);
  }

  async put<T>(path: string, body = ({} = {}), format: Format = 'json') {
    return await this._reqWithBody<T>('PUT', path, body, format);
  }

  async delete<T>(path: string, body = ({} = {}), format: Format = 'json') {
    return await this._reqWithBody<T>('DELETE', path, body, format);
  }

  url(path: string) {
    if (path.indexOf('https://') === 0 || path.indexOf('http://') === 0) {
      return path;
    }
    return `${this.base}${path}`;
  }

  async get<T>(path: string, params = {}) {
    let url = this.url(path);
    const qs = this.getQuery(params);
    if (qs) {
      url = `${url}?${qs}`;
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: this.headers(undefined, path),
      credentials: 'include',
    });
    return this.handleResponse<T>(response);
  }

  public headers(format: Format = 'urlencoded', path: string): { [idx: string]: string } {
    const auth: any = {};
    return { ...this._headers, ...auth, 'Content-Type': this.getContentType(format) };
  }

  getFetcher<T>(path: string) {
    return async () => {
      return this.get<T>(path);
    };
  }

  private async _reqWithBody<T>(
    method: 'POST' | 'PUT' | 'DELETE',
    path: string,
    body = ({} = {}),
    format: Format = 'json',
  ) {
    const response = await fetch(this.url(path), {
      method,
      headers: this.headers(format, path),
      body: this.getBody(format, body),
      credentials: 'include',
    });
    return await this.handleResponse<T>(response);
  }

  private async handleResponse<T>(response: Response): Promise<T> {
    let json = null;
    try {
      json = await response.json();
    } catch (err) {}

    if (!response.ok) {
      const err = new ApiError(`Error: ${response.statusText}`);
      err.status = response.status;
      err.resJson = json;
      throw err;
    }
    return json as T;
  }

  public getQuery(params: { [idx: string]: string }) {
    const keys = Object.keys(params);
    return keys.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
  }
}

export default new Api();
