import React from 'react';

const Twitter: React.FC = () => (
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 3.55556V1.77778H12.4444V0H10.5914H8.88889V1.77778H7.11111V5.33333H5.33333V3.55556H1.77778V1.77778H0V8.88889H1.77778V10.6667H3.55556V12.4444H0V14.2222H10.6667V12.4444H12.4444V10.6667H14.2222V5.33333H16V3.55556H14.2222Z"
      fill="currentColor"
    />
  </svg>
);

export default Twitter;
