import React from 'react';

const Discord: React.FC = () => (
  <svg viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2483 5.84223V3.64733H14.464V1.49884H13.8005V0.714617H13.1044V0H10.1717V0.784223H11.6381V1.42923H13.1044V2.16241H13.7819V2.92807H13.0162V2.23202H10.8353V1.46636H8H5.16473V2.23202H2.98376V2.92807H2.21346V2.16241H2.89559V1.42923H4.36195V0.784223H5.82831V0H2.89559V0.714617H2.19954V1.49884H1.53596V3.64269H0.75174V5.84223H0V10.9002H0.714617V11.6659H1.42923V12.3991H4.34339V11.7169H5.02552V10.9049H3.64733V10.2088H5.10905V10.9049H8H10.891V10.2088H12.3527V10.9049H10.9745V11.7262H11.6566V12.4084H14.5708V11.6752H15.2854V10.9049H16V5.84687H15.2483V5.84223ZM7.29002 8.70534H6.57541V9.471H4.39443V8.7239H3.61021V6.56148H4.39443V5.84687H6.52436V6.56148H7.29002V8.70534ZM12.413 8.70534H11.6984V9.471H9.5174V8.7239H8.73318V6.56148H9.5174V5.84687H11.6473V6.56148H12.413V8.70534Z"
      fill="currentColor"
    />
  </svg>
);

export default Discord;
