import React from 'react';
import BeatLoader from './BeatLoader';

interface Props {
  loading?: boolean;
  size?: number;
  className?: string;
}

export default function Loading({ loading = true, size, className = '' }: Props) {
  if (!loading) {
    return null;
  }
  return (
    <div data-testid="loading" className={className}>
      <BeatLoader size={size || 20} color={'rgb(0, 255, 0)'} loading={loading} />
    </div>
  );
}
