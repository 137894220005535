import React from 'react';
import Box from '@/components/box/Box';
import Transition from '@/components/transition/Transition';

type ModalProps = {
  title: string;
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

const Modal: React.FC<ModalProps> = ({ title, show, children, onClose }: ModalProps) => (
  <div>
    <Transition
      show={show}
      appear={true}
      enter="transition ease-out duration-300 transform"
      enterFrom="opacity-0 scale-95 "
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-200 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95">
      <div className="fixed z-30 inset-0 transition-opacity" onClick={onClose} aria-hidden="true">
        <div className="absolute  inset-0 bg-black opacity-90" />
      </div>
    </Transition>
    <Transition
      show={show}
      appear={true}
      enter="transition ease-out duration-300 transform"
      enterFrom="opacity-0  translate-x-1/2 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="translate-y-0 sm:scale-100"
      leave="transition ease-in duration-200 transform"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 translate-x-1/2 sm:translate-y-0 sm:scale-95">
      <div className="absolute top-4.5 top-4 sm:right-[33px]  right-0 z-30 h-96 w-full sm:w-auto sm:min-w-[25rem] min-h-[calc(80vh-105px)] flex-col flex">
        <Box title={title} onClose={onClose} children={children} />
      </div>
    </Transition>
  </div>
);

export default Modal;
