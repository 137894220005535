import { useEffect } from 'react';

export default function useClickOutside(ref: React.RefObject<HTMLElement>, callback: Function) {
  useEffect(() => {
    function handleClickOutside(event: React.MouseEvent<HTMLElement>) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    // @ts-ignore
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      // @ts-ignore
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);
}
