export default function IconCloseHappy() {
  return (
    <svg viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 0H6.01361V2.01361H4V0Z" fill="#00FF00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.98633 0H3.99993V2.01361H1.98633V0Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.01367H2.01361V4.02728H0V2.01367Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.01367 2.01367H8.02728V4.02728H6.01367V2.01367Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0684 0H16.082V2.01361H14.0684V0Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0547 0H14.0683V2.01361H12.0547V0Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.041 2.01367H12.0546V4.02728H10.041V2.01367Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.082 2.01367H18.0956V4.02728H16.082V2.01367Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77344 12.7998H13.7734V14.7998H4.77344V12.7998Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77344 9.7998H4.77344V12.7998H2.77344V9.7998Z"
        fill="#00FF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7734 9.7998H15.7734V12.7998H13.7734V9.7998Z"
        fill="#00FF00"
      />
    </svg>
  );
}
