import {
  BufferGeometry,
  DoubleSide,
  Group,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  Vector2,
  WebGLRenderer,
  WireframeGeometry,
} from 'three';
import { isTabletOrMobile } from '@/lib/utils';

const config = {
  useGlitchEffect: false,
  useFilmEffect: true,
  useBloomEffect: true,
};
const mouse = new Vector2();

let callbackTimer = 0;

export default async function start(canvas: HTMLCanvasElement) {
  const EffectComposer = (await import('three/examples/jsm/postprocessing/EffectComposer'))
    .EffectComposer;
  const RenderPass = (await import('three/examples/jsm/postprocessing/RenderPass')).RenderPass;
  const UnrealBloomPass = (await import('three/examples/jsm/postprocessing/UnrealBloomPass'))
    .UnrealBloomPass;
  const FilmPass = (await import('three/examples/jsm/postprocessing/FilmPass')).FilmPass;
  const GlitchPass = config.useGlitchEffect
    ? (await import('three/examples/jsm/postprocessing/GlitchPass')).GlitchPass
    : null;
  const GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader')).GLTFLoader;
  const scene = new Scene();
  const renderer = new WebGLRenderer({
    antialias: true,
    canvas,
    alpha: true,
  });
  const sizes = {
    width: 0,
    height: 0,
  };
  const camera = new PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 10000);
  refreshSize();
  camera.position.set(8, 0, 0);
  camera.lookAt(scene.position);
  const composer = new EffectComposer(renderer);
  const renderPass = new RenderPass(scene, camera);
  composer.addPass(renderPass);
  const loader = new GLTFLoader();
  const bloomPass = new UnrealBloomPass(new Vector2(sizes.width, sizes.height), 1.5, 0.4, 0.85);
  if (config.useBloomEffect) {
    const params = {
      exposure: 1,
      bloomStrength: 2.5,
      bloomThreshold: 0,
      bloomRadius: 1.0,
    };

    bloomPass.threshold = params.bloomThreshold;
    bloomPass.strength = params.bloomStrength;
    bloomPass.radius = params.bloomRadius;
    composer.addPass(bloomPass);
  }

  if (config.useFilmEffect) {
    const filmPass = new FilmPass(1, 0, 0, 0);
    composer.addPass(filmPass);
  }

  let model: Group;

  loader.load('/models/porchetta.gltf', (gltf) => {
    // @ts-ignore
    const geoHead: BufferGeometry = gltf.scene.children[0].children[0].geometry;
    // @ts-ignore
    const geoEyes: BufferGeometry = gltf.scene.children[0].children[1].geometry;
    const headMaterial = new MeshBasicMaterial({ color: 0x000000 });

    const hog = new Mesh(geoHead, headMaterial);

    const eyesMaterial = new MeshBasicMaterial({ color: 0x66ff99 });
    eyesMaterial.side = DoubleSide;

    const hogEyes = new Mesh(geoEyes, eyesMaterial);

    const wireframeGeometry = new WireframeGeometry(geoHead);
    const wireframeMaterial = new LineBasicMaterial({
      color: 0x10ff03,
    });
    const wireframe = new LineSegments(wireframeGeometry, wireframeMaterial);
    hog.add(wireframe);

    const group = new Group();
    group.add(hog);
    group.add(hogEyes);

    model = group;

    scene.add(group);
    renderer.render(scene, camera);
  });

  function onMouseMove(event: MouseEvent) {
    mouse.x = (event.clientX / innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / innerHeight) * 2 + 1;
  }

  function onMouseDown(event: MouseEvent) {
    if (!GlitchPass) {
      return;
    }
    const glitchPass = new GlitchPass(500);
    composer.addPass(glitchPass);
    setTimeout(() => {
      composer.removePass(glitchPass);
    }, 800);
  }

  window.addEventListener('mousemove', onMouseMove, false);
  if (config.useGlitchEffect) {
    window.addEventListener('mousedown', onMouseDown, false);
  }

  window.addEventListener('resize', refreshSize);

  // document.body.addEventListener('scroll', (e) => {
  //   console.log(e, sizes.height);
  // });

  let innerWidth = window.innerWidth;
  let innerHeight = window.innerHeight;

  function refreshSize() {
    const parent = canvas.parentElement;
    if (parent === null) {
      return;
    }
    innerWidth = window.innerWidth;
    innerHeight = window.innerHeight;

    const canvasW = parent.getBoundingClientRect().width;
    const canvasH = parent.getBoundingClientRect().height;
    canvas.width = canvasW;
    canvas.height = canvasH;
    sizes.width = canvasW;
    sizes.height = canvasH;
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  let lastCalledTime = performance.now();
  let fps = 0;
  let isDevice = isTabletOrMobile();
  let deviceRenders = 10;

  function render() {
    let delta = (performance.now() - lastCalledTime) / 1000;
    if (delta < 0.06) {
      callbackTimer = requestAnimationFrame(render);
      return;
    }
    lastCalledTime = performance.now();
    fps = 1 / delta;

    if (model) {
      model.rotation.y = mouse.x * 0.3;
      model.rotation.z = mouse.y * 0.3;
    }

    composer.render();
    if (!isDevice || deviceRenders-- > 0) {
      // don't loop on devices
      callbackTimer = requestAnimationFrame(render);
    }
  }

  callbackTimer = requestAnimationFrame(render);
}

export function cancel() {
  cancelAnimationFrame(callbackTimer);
}
