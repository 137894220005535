import * as React from 'react';
import RegisterForm from '@/components/forms/RegisterForm';
import Modal from '@/components/Modal/Modal';
import useModal from '@/components/hooks/useModal';

const ModalRegister = () => {
  const { closeRegister, modalRegister } = useModal();
  return (
    <Modal title="Register your company" show={modalRegister} onClose={closeRegister}>
      <div className="flex justify-center p-4">
        <div className="flex flex-col">
          <h2 className="text-5xl mt-5 uppercase font-titles-bold font-extrabold ">Register</h2>
          <div className="my-16">
            <RegisterForm />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRegister;
