import React, { MouseEventHandler } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ThemePorchetta } from '@/lib/legacy-theme';
import { classNames } from '@/lib/utils';
import Loading from '@/components/spinner/Loading';

type StyledButtonProps = {
  size: 'small' | 'large';
  dark?: boolean;
  outline?: boolean;
  active?: boolean;
};

type ButtonProps = StyledButtonProps & {
  children: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  icon?: React.ReactElement | null;
  type?: 'submit' | 'button';
  loading?: boolean;
  disabled?: boolean;
};

const button = {
  border: {
    small: 0.25 / 4,
    large: 0.25,
  },
  padding: {
    small: {
      vertical: 0.5,
      horizontal: 0.5,
    },
    large: {
      vertical: 0.5,
      horizontal: 1,
    },
  },
};

const border = ({ theme, size, dark, outline }: { theme: ThemePorchetta } & StyledButtonProps) =>
  outline
    ? css`
        border: ${size === 'small' ? `${button.border.small}rem` : `${button.border.large}rem`}
          solid ${dark ? theme.colors.green : theme.colors.white};
      `
    : null;

const padding = ({ size, outline }: StyledButtonProps) =>
  outline
    ? css`
        padding-bottom: ${size === 'small'
          ? `${button.padding.small.vertical}rem`
          : `${button.padding.large.vertical}rem`};
        padding-left: ${size === 'small'
          ? `${button.padding.small.horizontal}rem`
          : `${button.padding.large.horizontal}rem`};
        padding-right: ${size === 'small'
          ? `${button.padding.small.horizontal}rem`
          : `${button.padding.large.horizontal}rem`};
        padding-top: ${size === 'small'
          ? `${button.padding.small.vertical}rem`
          : `${button.padding.large.vertical}rem`};
      `
    : css`
        padding-bottom: ${size === 'small'
          ? `${button.padding.small.vertical + button.border.small}rem`
          : `${button.padding.large.vertical + button.border.large}rem`};
        padding-left: ${size === 'small'
          ? `${button.padding.small.horizontal + button.border.small}rem`
          : `${button.padding.large.horizontal + button.border.large}rem`};
        padding-right: ${size === 'small'
          ? `${button.padding.small.horizontal + button.border.small}rem`
          : `${button.padding.large.horizontal + button.border.large}rem`};
        padding-top: ${size === 'small'
          ? `${button.padding.small.vertical + button.border.small}rem`
          : `${button.padding.large.vertical + button.border.large}rem`};
      `;

const StyledButton = styled.button<StyledButtonProps>`
  background: ${({ theme }) => theme.colors.dark};
  border: none;
  color: ${({ theme, dark }) => (dark ? theme.colors.green : theme.colors.white)};
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;

  ${border};
  ${padding};

  & > span {
    display: flex;

    & > svg {
      margin-right: 0.35rem;

      & > path {
        fill: ${({ theme, dark }) => (dark ? theme.colors.green : theme.colors.white)};
      }
    }
  }

  &:hover {
    background: ${({ theme, dark }) => (dark ? theme.colors.green : theme.colors.dark)};
    border-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme, dark }) => (dark ? theme.colors.dark : theme.colors.green)};

    & > span > svg > path {
      fill: ${({ theme, dark }) => (dark ? theme.colors.dark : theme.colors.green)};
    }
  }
  &.loading {
    color: #003c00;
    border-color: #003c00;
    cursor: not-allowed;
    &:hover {
      background: transparent;
    }
  }
  &.disabled {
    color: #003c00;
    border-color: #003c00;
    cursor: not-allowed;
    &:hover {
      background: transparent;
    }
  }

  &:active {
    background: ${({ theme }) => theme.colors.green};
    border-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.dark};

    & > span > svg > path {
      fill: ${({ theme }) => theme.colors.dark};
    }
  }
  &.active {
    background: ${({ theme }) => theme.colors.green};
    border-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.dark};

    & > span > svg > path {
      fill: ${({ theme }) => theme.colors.dark};
    }
  }
`;

const Button: React.FC<ButtonProps> = ({
  size,
  type = 'button',
  children,
  onClick = () => {},
  dark = false,
  outline = false,
  active = false,
  icon = null,
  loading = false,
  disabled = false,
}: ButtonProps) => {
  const content =
    icon && !loading ? (
      <span>
        {icon} {children}
      </span>
    ) : (
      children
    );

  return (
    <StyledButton
      type={type}
      className={classNames({
        active,
        'inline-flex flex-row items-center': true,
        loading,
        disabled,
      })}
      size={size}
      dark={dark}
      outline={outline}
      onClick={onClick}
      disabled={loading || disabled}
      active={active}>
      <Loading size={5} loading={loading} className={'mr-1'} />
      {content}
    </StyledButton>
  );
};

export default Button;
