import { useState } from 'react';
import { createContainer } from 'react-tracked';
import IAlert from '@/lib/res/IAlert';

export type State = {
  alerts: IAlert[];
  modalLogin: boolean;
  modalRegister: boolean;
};

const useValue = () => {
  return useState<State>({
    alerts: [],
    modalLogin: false,
    modalRegister: false,
  });
};

export type Dispatcher = React.Dispatch<React.SetStateAction<State>>;

export const { Provider, useTrackedState, useUpdate: useSetState, useTracked } = createContainer(
  useValue,
);
