import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ROUTES, { isValidRedirect } from '@/lib/routes';
import Loading from '@/components/spinner/Loading';
import Button from '@/components/inputs/Button';
import useAlerts from '@/components/hooks/useAlert';
import { getCookieValue, handleResponseError } from '@/lib/utils';
import useModal from '@/components/hooks/useModal';
import useOAuthUser from '../hooks/useOAuthUser';

export default function RegisterForm() {
  const router = useRouter();
  const { addAlert } = useAlerts();
  const { closeRegister } = useModal();
  const { oAuthUser, loading, mutateOAuth } = useOAuthUser();
  const [loginBtnClicked, setLoginBtnClicked] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const isLoggedIn = getCookieValue('logged_in');

      if (isLoggedIn) {
        try {
          mutateOAuth();
        } catch (e) {
          handleResponseError(e, addAlert);
        }
        clearInterval(interval);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (oAuthUser) {
      closeRegister();
      const next = isValidRedirect(router.query.next)
        ? (router.query.next as string)
        : ROUTES.subscriptions;
      router.replace(next);
    }
  }, [oAuthUser]);

  const loginWithGitea = () => {
    setLoginBtnClicked(true);
    window.open(`${process.env.NEXT_PUBLIC_API_BASE}/auth/login`);
  };

  const registerWithGitea = () => {
    setLoginBtnClicked(true);
    window.open(`${process.env.NEXT_PUBLIC_API_BASE}/user/sign_up`);
  };

  if (loading || oAuthUser) {
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <Button loading={loginBtnClicked} onClick={loginWithGitea} outline dark size={'large'}>
        Register With Gitea
      </Button>
      <p className="pt-5">Already a Member?</p>
      <Button loading={loginBtnClicked} onClick={loginWithGitea} outline dark size={'large'}>
        Login With Gitea
      </Button>
    </>
  );
}
