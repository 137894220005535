import React from 'react';

const LinkedIn: React.FC = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0833 0.819974V0H0.916689V0.819974H0V14.133H0.790539V15.0833H15.2095V14.2633H16V0.819974H15.0833ZM4.97871 12.5855H2.56084V5.88279H4.97871V12.5855ZM4.97871 5.11327H2.56084V2.5272H4.97871V5.11327ZM13.3887 12.5855H10.9708V8.31748H9.20053V9.23417H8.39317V12.5855H5.9753V5.88279H8.39317V6.70276H9.20053V5.88279H12.5855V6.70276H13.3929V12.5855H13.3887Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedIn;
