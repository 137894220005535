import React, { Fragment, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import ROUTES, { isActive } from '@/lib/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useClickOutside from '@/components/hooks/useClickOutside';
import IconAnonymous from '@/components/icons/IconAnonymous';
import useModal from '@/components/hooks/useModal';
import Button from '@/components/inputs/Button';
import { OAuthUser } from '@/lib/res/IUser';
import { classNames } from '@/lib/utils';
import Loading from '@/components/spinner/Loading';
import useOAuthUser from '../hooks/useOAuthUser';

const CLASSES = {
  DESKTOP: {
    MENU: {
      CURRENT:
        'border-porchetta-green text-porchetta-green hover:text-porchetta-green inline-flex items-center px-1 pt-1 text-sm font-medium',
      NORMAL:
        'border-transparent text-porchetta-white hover:text-porchetta-green inline-flex items-center px-1 pt-1  text-sm font-medium',
    },
  },
  MOBILE: {
    MENU: {
      NORMAL:
        'border-transparent text-gray-600 hover:bg-gray-900 hover:border-porchetta-green hover:text-porchetta-white block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
      CURRENT:
        'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
    },
  },
};

const MENU_ITEMS = [
  {
    label: 'Manifesto',
    route: '#manifest',
  },
  {
    label: 'Tools',
    route: '#tools',
  },
  {
    label: 'Subscriptions',
    route: '#subscriptions',
  },
  {
    label: 'Newsletter',
    route: '#newsletter',
  },
  {
    label: 'FAQ',
    route: ROUTES.faq,
  },
  {
    label: 'Blog',
    route: 'https://blog.porchetta.industries',
  },
];

function NotLoggedItems({ outlineLogin }: { outlineLogin: boolean }) {
  const { openLogin, openRegister, modalLogin, modalRegister } = useModal();
  return (
    <div className="ml-4 relative flex-shrink-0 flex flex-col md:flex-row justify-between max-w-xs">
      <Button
        dark
        outline={outlineLogin}
        size="large"
        active={modalLogin}
        onClick={(ev) => {
          ev.preventDefault();
          openLogin();
        }}>
        Login
      </Button>
      <div className="sep mt-4 ml-2"></div>
      <Button
        outline
        dark
        size="large"
        active={modalRegister}
        onClick={(ev) => {
          ev.preventDefault();
          openRegister();
        }}>
        Register
      </Button>
    </div>
  );
}

function ProfileDesktop({ user }: { user: OAuthUser }) {
  const { doLogout } = useOAuthUser();
  return (
    <Menu as="div" className="ml-4 relative flex-shrink-0">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-transparent flex text-sm focus:outline-none focus:ring-0">
              <span className="sr-only">Open user menu</span>
              <div className="w-6 h-6 border border-porchetta-green flex text-porchetta-green bg-transparent">
                <IconAnonymous />
              </div>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48  shadow-lg py-1 bg-porchetta-dark border-porchetta-green border shadow-porchetta ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link href={ROUTES.subscriptions}>
                    <a
                      href="#"
                      className={classNames({
                        'block px-4 py-2 text-sm text-porchetta-white hover:text-porchetta-green': true,
                      })}>
                      Buy a subscription
                    </a>
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <Link href={ROUTES.profile}>
                    <a
                      href="#"
                      className={classNames({
                        'block px-4 py-2 text-sm text-porchetta-white hover:text-porchetta-green': true,
                      })}>
                      Profile
                    </a>
                  </Link>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={doLogout}
                    href="#"
                    className={classNames({
                      'block px-4 py-2 text-sm text-porchetta-white hover:text-porchetta-green': true,
                    })}>
                    Sign out
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

function ProfileMobile({ user }: { user: OAuthUser }) {
  const { doLogout } = useOAuthUser();
  return (
    <div className="pt-4 pb-3 border-t border-gray-700">
      <div className="flex items-center px-4">
        <div className="flex-shrink-0">
          <div className="w-10 h-10 text-porchetta-green bg-porchetta-dark">
            {user?.picture ? <img src={user?.picture} /> : <IconAnonymous />}
          </div>
        </div>
        <div className="ml-3">
          <div className="text-base font-medium text-gray-50">{user.name}</div>
          <div className="text-sm font-medium text-gray-500">{user.email}</div>
        </div>
      </div>
      <div className="mt-3 space-y-1">
        <Link href={ROUTES.subscriptions}>
          <a className={CLASSES.MOBILE.MENU.NORMAL}>Buy a subscription</a>
        </Link>

        <Link href={ROUTES.profile}>
          <a className={CLASSES.MOBILE.MENU.NORMAL}>Profile</a>
        </Link>

        <a href="#" onClick={doLogout} className={CLASSES.MOBILE.MENU.NORMAL}>
          Logout
        </a>
      </div>
    </div>
  );
}

export default function Navbar() {
  const router = useRouter();
  const { oAuthUser: user, loading } = useOAuthUser();
  const [openMenu, setOpenMenu] = useState(false);
  const menuOpenRef = useRef(null);
  useClickOutside(menuOpenRef, () => {
    setOpenMenu(false);
  });
  const openItem = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const href = event.currentTarget.getAttribute('href') || '#';
    if (href[0] !== '#') {
      return;
    }
    event.preventDefault();
    const hash = (event.currentTarget.getAttribute('href') || '').substring(1);
    const pathname = ROUTES.home;
    const url = { pathname, hash };
    const opt = { shallow: true };
    if (router.pathname !== ROUTES.home) {
      opt.shallow = false;
    }
    const el = document.querySelector(`#${hash}`);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }
    setTimeout(() => router.push(url, undefined, opt), 1000);
  };

  return (
    <nav className="bg-transparent shadow relative z-30">
      <div className="mx-auto px-0 xl:px-10">
        <div className="flex justify-between h-16">
          <div className="flex flex-grow">
            <div className="flex-shrink-0 flex items-center">
              <Link href={ROUTES.home}>
                <a>
                  <img className="block h-14 w-auto" src="/images/logo-porchetta-header.svg" />
                </a>
              </Link>
            </div>
            <div className="hidden  flex-grow justify-end md:ml-6 md:flex md:space-x-2">
              {MENU_ITEMS.map((item) => (
                <a
                  className={
                    isActive(router, item.route)
                      ? CLASSES.DESKTOP.MENU.CURRENT
                      : CLASSES.DESKTOP.MENU.NORMAL
                  }
                  key={item.route}
                  href={item.route}
                  onClick={openItem}>
                  <span className="h-9 flex flex-grow items-center hover:border-porchetta-white border-transparent border-b-2">
                    {item.label}
                  </span>
                </a>
              ))}
            </div>
          </div>

          <div className="flex items-center md:hidden">
            {/* Mobile menu button */}
            <button
              onClick={() => {
                setOpenMenu((x) => !x);
              }}
              ref={menuOpenRef}
              className="inline-flex items-center justify-center rounded-md text-porchetta-green hover:text-porchetta-white focus:outline-none focus:ring-0">
              <span className="sr-only">Open main menu</span>
              {openMenu ? (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          <div className="hidden lg:ml-4 md:flex md:items-center">
            {loading ? (
              <Loading />
            ) : (
              <>
                {!user && <NotLoggedItems outlineLogin={false} />}
                {user && <ProfileDesktop user={user} />}
              </>
            )}
          </div>
        </div>
      </div>

      <div className={classNames({ 'md:hidden': true, hidden: !openMenu })}>
        <div className="pt-2 pb-3 space-y-1">
          {MENU_ITEMS.map((item) => (
            <a
              key={item.route}
              href={item.route}
              onClick={openItem}
              className={
                false && isActive(router, item.route)
                  ? CLASSES.MOBILE.MENU.CURRENT
                  : CLASSES.MOBILE.MENU.NORMAL
              }>
              {item.label}
            </a>
          ))}
        </div>
        {!user && <NotLoggedItems outlineLogin={true} />}
        {user && <ProfileMobile user={user} />}
      </div>
    </nav>
  );
}
