import { useTracked } from '@/components/App/Store';

export default function useModal() {
  const [{ modalLogin, modalRegister }, dispatch] = useTracked();
  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const openLogin = () => {
    toTop();
    dispatch((prev) => {
      return { ...prev, modalLogin: true, modalRegister: false };
    });
  };
  const closeLogin = () => {
    dispatch((prev) => {
      return { ...prev, modalLogin: false };
    });
  };
  const closeRegister = () => {
    dispatch((prev) => {
      return { ...prev, modalRegister: false };
    });
  };
  const openRegister = () => {
    toTop();
    dispatch((prev) => {
      return { ...prev, modalRegister: true, modalLogin: false };
    });
  };

  return {
    openLogin,
    openRegister,
    closeRegister,
    closeLogin,
    modalLogin,
    modalRegister,
  };
}
