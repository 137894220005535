import IAlert from '@/lib/res/IAlert';
import { useTracked } from '@/components/App/Store';

export default function useAlerts() {
  const [{ alerts }, dispatch] = useTracked();

  const addAlert = (alert: IAlert) => {
    const id = alert.id || Date.now().toString();
    dispatch((prev) => {
      const alerts = prev.alerts.filter((x) => x.id !== id).concat([{ id, ...alert }]);
      console.log({ alerts });
      return { ...prev, alerts };
    });
  };
  const removeAlert = (alert: IAlert) => {
    dispatch((prev) => {
      return {
        ...prev,
        alerts: prev.alerts.filter((x) => x.id !== alert.id),
      };
    });
  };

  return {
    alerts,
    addAlert,
    removeAlert,
  };
}
