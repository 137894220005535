import * as React from 'react';
import CSS from 'csstype';

const cssValue = (n: number) => `${n.toString()}px`;

export default class BeatLoader extends React.PureComponent<{
  size?: number;
  color?: string;
  loading?: boolean;
  margin?: number;
  css?: CSS.Properties;
}> {
  public static defaultProps = {
    size: 15,
    color: '#000000',
    loading: true,
    margin: 2,
  };

  public style = (i: number): CSS.Properties => {
    const { color, size, margin } = this.props;

    return {
      display: 'inline-block',
      backgroundColor: `${color}`,
      width: `${cssValue(size || BeatLoader.defaultProps.size)}`,
      height: `${cssValue(size || BeatLoader.defaultProps.size)}`,
      margin: `${cssValue(margin || BeatLoader.defaultProps.margin)}`,
      borderRadius: `0%`,
      animation: `beat 0.7s ${i % 2 ? '0s' : '0.35s'} infinite linear`,
      animationFillMode: `both`,
    };
  };

  public render(): JSX.Element | null {
    const { loading, css } = this.props;

    return loading ? (
      <span style={css}>
        <span style={this.style(1)} />
        <span style={this.style(2)} />
        <span style={this.style(3)} />
      </span>
    ) : null;
  }
}
